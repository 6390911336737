import { AppState, ProbeArgument } from '../createStore';
import {
  clearSessionStorage,
  initApp,
  saveAddressToServer,
  saveStateToSessionStorage,
  setIsLoadingAddressesFromServer,
  setIsUserLoggedIn,
  setSavedAddresses,
  setSignedInstance,
  userLoggedIn,
  changeLocation,
  navigate,
  setIsLoadingLocationFromServer,
  setOrganizationFull,
  setLocations,
  closeModal,
  setIsMAInstalled,
  runSideEffectsAfterFirstRender,
  setIsLocationPicked,
  setLoadingLocationsSettingsFromServer,
  setLoyaltyRewards,
  setLoyaltyAccount,
  setLoyaltyProgram,
  goToLoyaltyPage,
  setLoyaltyEarningRules,
} from './session.actions';
import { clearCart } from '../cart/cart.actions';
import { Modals, RouteUrls, SESSION_STORAGE_KEY } from '../../core/constants';
import moment from 'moment-timezone';
import { AddressesWeb, ListResponse } from '@wix/ambassador-addresses-web/http';
import {
  initCheckout,
  setCheckoutStep,
  setDeliveryAddress,
  setMemberContactLoading,
  setMembersAPiContact,
  setSelectedAddressId,
} from '../checkout/checkout.actions';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { Dispatch } from 'redux';
import {
  Action,
  extractLocalizedString,
  getOrganizationAndMenu,
  hasLoyaltySupport,
  isAddress,
} from '@wix/restaurants-client-logic';
import WixInstance from '@wix/wixrest-utils/dist/WixInstance';
import { ChangeLocationPayload, SaveAddressToServerPayload } from './session.actions.types';
import { getMemberContactDetails } from '../checkout/checkout.probe.utils';
import { convertMembersAddressToOloAddress, getUpdateAddressObjectPaths } from '../../core/logic/addressLogic';
import { getLocationsSettings, PartialLocation } from '../../core/oloApi';
import { getBaseUrlForMappedServices } from '../../core/logic/urlLogic';
import { componentSettings } from '../../components/MainPage/componentSettings';
import { LoyaltyRewards } from '@wix/ambassador-loyalty-rewards/http';
import { LoyaltyAccounts } from '@wix/ambassador-loyalty-accounts/http';
import { LoyaltyPrograms } from '@wix/ambassador-loyalty-programs/http';
import { LoyaltyCalculator } from '@wix/ambassador-loyalty-calculator/http';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import _ from 'lodash';

async function getLoggedInUserEmail(flowAPI: ControllerFlowAPI): Promise<string | undefined> {
  try {
    return await flowAPI.controllerConfig.wixCodeApi.user.currentUser.getEmail();
  } catch (e) {
    return undefined;
  }
}

export default function sessionProbe({ onAction, onActionOnce }: ProbeArgument) {
  onAction(initApp.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const experiments = await flowAPI.getExperiments();
    const state = getState();
    const isLoyaltyEnabled = hasLoyaltySupport(state.session.menu.chargesV2);
    const isMembersContactEnabled = experiments.enabled('specs.restaurants.olo-client-members-area-contact');
    const isMembersWalletEnabled = experiments.enabled('specs.restaurants.olo-client-members-area-my-wallet');
    const signedInstance = getSignedInstance(flowAPI);
    const isMultiLocation = state.session.isMultiLocation;
    const restaurant = state.session.restaurant;
    const restaurantId = restaurant.id;

    const baseUrlForMappedServices = getBaseUrlForMappedServices({
      websiteUrl: flowAPI.controllerConfig.wixCodeApi.location.baseUrl,
      environment: flowAPI.environment,
    });

    if (flowAPI.biLogger) {
      flowAPI.biLogger.initSession({ projectName: undefined, locationGuid: restaurant.currentLocationId } as any);
    }
    const isLoggedIn = flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor';
    dispatch(
      setIsUserLoggedIn({
        isLoggedIn,
        loggedInUserEmail: await getLoggedInUserEmail(flowAPI),
      }),
    );

    const { isMembersAddressEnabled, shouldInitMemberAddresses } = await checkMembersAreaPrerequisits(flowAPI);

    if (shouldInitMemberAddresses) {
      await initMemberAddresses(flowAPI, dispatch, state);
    }

    if ((isMembersAddressEnabled || isMembersContactEnabled || isMembersWalletEnabled) && !flowAPI.environment.isSSR) {
      flowAPI.controllerConfig.wixCodeApi.user.onLogin(() => {
        dispatch(userLoggedIn());
      });
    }

    if (isMultiLocation && _.isEmpty(getState().session.locations)) {
      dispatch(setLoadingLocationsSettingsFromServer({ isLoadingLocationsSettingsFromServer: true }));
      const locations = await getLocationsSettings(signedInstance, restaurantId, baseUrlForMappedServices);
      dispatch(setLoadingLocationsSettingsFromServer({ isLoadingLocationsSettingsFromServer: false }));
      dispatch(setLocations({ locations }));
    }

    await fetchLoyaltyDataIfNeeded(flowAPI, dispatch, isLoyaltyEnabled, isLoggedIn);
  });

  onAction(initCheckout.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const state = getState();
    const isLoyaltyEnabled = hasLoyaltySupport(state.session.menu.chargesV2);
    const isLoggedIn = flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor';
    await fetchLoyaltyDataIfNeeded(flowAPI, dispatch, isLoyaltyEnabled, isLoggedIn);
  });

  onActionOnce(userLoggedIn.toString(), async (action, getState, dispatch, { flowAPI }) => {
    dispatch(setCheckoutStep({ step: 'address-information' }));
    dispatch(
      setIsUserLoggedIn({
        isLoggedIn: true,
        loggedInUserEmail: await getLoggedInUserEmail(flowAPI),
      }),
    );
    dispatch(setSignedInstance({ signedInstance: getSignedInstance(flowAPI) }));
    const { shouldInitMemberAddresses } = await checkMembersAreaPrerequisits(flowAPI);

    if (shouldInitMemberAddresses) {
      await initMemberAddresses(flowAPI, dispatch, getState());
    }
    dispatch(setMemberContactLoading({ loading: true }));
    const contact = await getMemberContactDetails(flowAPI);
    if (contact) {
      dispatch(setMembersAPiContact({ contact }));
    }
    dispatch(setMemberContactLoading({ loading: false }));

    const isLoyaltyEnabled = hasLoyaltySupport(getState().session.menu.chargesV2);
    await fetchLoyaltyAccount(flowAPI, dispatch, isLoyaltyEnabled);
  });

  onAction(saveStateToSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    const { cart, checkout, addressForm } = getState();
    const { orderItems, coupon, comment } = cart;
    const { checkoutStep, contact, loyaltyPointsToRedeem } = checkout;
    const { selectedAddressOption } = addressForm;
    const timestamp = moment().valueOf();

    flowAPI.controllerConfig.platformAPIs.storage.session.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({
        orderItems,
        coupon,
        comment,
        dispatch: checkout.dispatch,
        checkoutStep,
        contact,
        selectedAddressOption,
        timestamp,
        loyaltyPointsToRedeem,
      }),
    );
  });

  onAction(clearSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    flowAPI.controllerConfig.platformAPIs.storage.session.removeItem(SESSION_STORAGE_KEY);
  });

  onAction(
    saveAddressToServer.toString(),
    async (action: Action<SaveAddressToServerPayload>, getState, dispatch, { flowAPI }) => {
      const signedInstance = getSignedInstance(flowAPI);
      const headers = { Authorization: signedInstance };
      const addressesService = AddressesWeb('/_api/addresses-web').Addresses()(headers);
      const { address, addressId, setAsDefault } = action.payload;

      dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: true }));

      if (addressId) {
        const paths = getUpdateAddressObjectPaths(address);
        if (setAsDefault) {
          paths.push('setAsDefault');
        }
        await addressesService.update({ address: { id: addressId, ...address }, setAsDefault, fieldMask: { paths } });
        dispatch(setSelectedAddressId({ id: addressId }));
      } else {
        const { id } = await addressesService.create({ address, setAsDefault });
        dispatch(setSelectedAddressId({ id }));
      }

      const { addresses, defaultAddressId } = await fetchMemberAddressesData(flowAPI);
      dispatch(setSavedAddresses({ addresses, defaultAddressId }));
      dispatch(closeModal({ modal: Modals.ADDRESS_SELECTION }));
      dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: false }));
    },
  );

  onAction(runSideEffectsAfterFirstRender.toString(), async (action: Action<any>, getState, dispatch, { flowAPI }) => {
    const membersAreaAppDefId = '14cffd81-5215-0a7f-22f8-074b0e2401fb';
    const restaurant = getState().session.restaurant;

    flowAPI.environment.isViewer &&
      flowAPI.controllerConfig.wixCodeApi.window.trackEvent('ViewContent', {
        origin: 'Restaurants',
        name: extractLocalizedString(restaurant.title, restaurant.locale),
      });

    try {
      const isMAInstalled = await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
        appDefinitionId: membersAreaAppDefId,
        sectionId: 'member_info',
      });

      dispatch(setIsMAInstalled({ isMAInstalled }));
    } catch (e) {
      console.log('Failed to set isMAInstalled', e);
    }
  });

  onAction(
    changeLocation.toString(),
    async (action: Action<ChangeLocationPayload>, getState, dispatch, { flowAPI }) => {
      /**
       *  - check if we need to change the location
       * No:
       * 1. put the location id in the url
       * 2. updated the state to indicate the user selected a location
       * Yes:
       * 1. indicate fetching menu
       * 2. fetch new menu and org and replace the old one
       * 3. clear cart
       * 4. put the location id in the url
       * 5. navigate to menus
       */
      const restaurant = getState().session.restaurant;
      if (restaurant.currentLocationId === action.payload.locationId) {
        flowAPI.controllerConfig.wixCodeApi.location.queryParams.add({
          locationId: restaurant.currentLocationId,
        });
        dispatch(setIsLocationPicked({ value: true }));
        return;
      }

      const signedInstance = getSignedInstance(flowAPI);

      dispatch(setIsLoadingLocationFromServer({ isLoadingLocationFromServer: true }));
      const full = await getOrganizationAndMenu(signedInstance, action.payload.locationId, true);
      if (full) {
        dispatch(clearCart());
        dispatch(setOrganizationFull({ organizationFull: full }));
        if (full.restaurant.currentLocationId) {
          flowAPI.controllerConfig.wixCodeApi.location.queryParams.add({
            locationId: full.restaurant.currentLocationId,
          });
          dispatch(setIsLocationPicked({ value: true }));
        }
      }
      dispatch(setIsLoadingLocationFromServer({ isLoadingLocationFromServer: false }));
      // dispatch(closeModal({ modal: Modals.ADDRESS_INFORMATION_MODAL }));
      // dispatch(initCheckout());
      // dispatch(clearSessionStorage());
      dispatch(navigate({ routeUrl: RouteUrls.INITIAL_VIEW }));
    },
  );

  onAction(goToLoyaltyPage.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const santaMembersAppDefinitionId = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

    const api = await flowAPI.controllerConfig.wixCodeApi.site.getPublicAPI(santaMembersAppDefinitionId);

    if (api && api.navigateToSection) {
      api.navigateToSection({
        appDefinitionId: MA_APP_IDS.MY_REWARDS.appDefinitionId,
        sectionId: MA_APP_IDS.MY_REWARDS.pageId,
        memberId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
      });
    }
  });
}

async function checkMembersAreaPrerequisits(flowAPI: ControllerFlowAPI) {
  const experiments = await flowAPI.getExperiments();
  const { isViewer } = flowAPI.environment;
  const hasMembersAreaIntegration = flowAPI.settings.get(componentSettings.hasMembersAreaIntegration);
  const isMembersAddressEnabled =
    hasMembersAreaIntegration && experiments.enabled('specs.restaurants.olo-client-members-area-addresses');
  const isUserLoggedIn = flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor';

  return {
    isMembersAddressEnabled,
    isUserLoggedIn,
    isViewer,
    shouldInitMemberAddresses: isMembersAddressEnabled && isUserLoggedIn && isViewer,
  };
}

export function getSignedInstance(flowAPI: ControllerFlowAPI) {
  return (
    flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(WixInstance.ORDERS_APP_ID) ||
    flowAPI.controllerConfig.appParams.instance
  );
}

async function fetchMemberAddressesData(flowAPI: ControllerFlowAPI) {
  const signedInstance = getSignedInstance(flowAPI);
  const headers = { Authorization: signedInstance };
  const addressesService = AddressesWeb('/_api/addresses-web').Addresses()(headers);
  const { addresses = [], defaultAddressId }: ListResponse = await addressesService.list({});
  return { addresses: addresses.filter((a) => Boolean(a.addressLine1)), defaultAddressId };
}

async function initMemberAddresses(flowAPI: ControllerFlowAPI, dispatch: Dispatch<Action<any>>, state: AppState) {
  dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: true }));
  const { addresses, defaultAddressId } = await fetchMemberAddressesData(flowAPI);
  dispatch(setSavedAddresses({ addresses, defaultAddressId }));
  dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: false }));

  const defaultAddress = addresses.find((address) => address.id === defaultAddressId);

  const hasAddress = state.checkout.dispatch.type === 'delivery' && isAddress(state.checkout.dispatch.address);

  if (defaultAddress && defaultAddress.addressLine1 && !hasAddress) {
    dispatch(
      setDeliveryAddress({
        address: convertMembersAddressToOloAddress(defaultAddress),
      }),
    );
  }
}

async function fetchLoyaltyDataIfNeeded(
  flowAPI: ControllerFlowAPI,
  dispatch: Dispatch<Action<any>>,
  isLoyaltyEnabled: boolean,
  isLoggedIn: boolean,
) {
  if (isLoyaltyEnabled) {
    const headers = { Authorization: getSignedInstance(flowAPI) };
    try {
      const { rewards: loyaltyRewards = [] } = await LoyaltyRewards('/_api/loyalty-rewards')
        .LoyaltyRewards()(headers)
        .listRewards({});

      dispatch(setLoyaltyRewards({ loyaltyRewards }));

      const { loyaltyProgram } = await LoyaltyPrograms('/_api/loyalty-programs')
        .LoyaltyPrograms()(headers)
        .getLoyaltyProgram({});

      if (loyaltyProgram) {
        dispatch(setLoyaltyProgram({ loyaltyProgram }));
      }

      const { rules: earningRules } = await LoyaltyCalculator('/_api/loyalty-calculator')
        .LoyaltyCalculator()(headers)
        .listEarningRules({
          triggerActivityType: 'wix-restaurants/orderSubmitted',
          triggerAppId: WixInstance.ORDERS_APP_ID,
        });

      if (earningRules) {
        dispatch(setLoyaltyEarningRules({ earningRules }));
      }

      if (isLoggedIn) {
        await fetchLoyaltyAccount(flowAPI, dispatch, isLoyaltyEnabled);
      }
    } catch (e) {}
  }
}

async function fetchLoyaltyAccount(
  flowAPI: ControllerFlowAPI,
  dispatch: Dispatch<Action<any>>,
  isLoyaltyEnabled: boolean,
) {
  if (isLoyaltyEnabled) {
    const headers = { Authorization: getSignedInstance(flowAPI) };

    const { account: loyaltyAccount = {} } = await LoyaltyAccounts('/_api/loyalty-accounts')
      .LoyaltyAccounts()(headers)
      .getCurrentMemberAccount({});

    dispatch(setLoyaltyAccount({ loyaltyAccount }));
  }
}
