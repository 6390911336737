import { ProbeArgument } from '../createStore';
import { revertAddress, setSelectedAddress, setAddressInputValue } from './addressForm.actions';
import { DeliveryDispatch } from '@wix/restaurants-client-logic';

export default function addressFormProbe({ onAction }: ProbeArgument) {
  onAction(revertAddress.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const checkoutAddress = (getState().checkout.dispatch as DeliveryDispatch).address;

    dispatch(
      setSelectedAddress({
        address: checkoutAddress,
      }),
    );

    dispatch(
      setAddressInputValue({
        value: checkoutAddress.formatted,
      }),
    );
  });
}
