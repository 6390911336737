import { createReducer } from '../common/reducerUtils';
import {
  setAddressInputValue,
  setDeliveryAddressField,
  setErrorVisibility,
  setFieldError,
  setAddressInputError,
  setSelectedAddress,
  toggleAllErrors,
  initAddressForm,
} from './addressForm.actions';
import { EMPTY_ADDRESS } from '../../core/constants';
import _ = require('lodash');
import {
  SetAddressInputErrorPayload,
  SetAddressInputValuePayload,
  SetDeliveryAddressFieldPayload,
  SetErrorVisibilityPayload,
  SetSelectedAddressPayload,
  ToggleAllErrorsPayload,
} from './addressForm.actions.types';
import { ActionHandlers, Address, AddressFormFields, ValidateAddressReason } from '@wix/restaurants-client-logic';
import { noop } from 'lodash';

export type DeliveryFormField =
  | 'addressInput'
  | 'apt'
  | 'timingOption'
  | 'addressLine2'
  | 'contactlessDineInInputLabel';

interface AddressInformationFormState {
  errorsVisibility: Record<DeliveryFormField, boolean>;
  addressInputError: ValidateAddressReason | undefined;
  selectedAddressOption: Address;
  addressInputValue: string;
  fieldErrors: {
    apt: boolean;
  };
}

const initialState: AddressInformationFormState = {
  addressInputError: undefined,
  errorsVisibility: {
    apt: false,
    addressInput: false,
    timingOption: false,
    addressLine2: false,
    contactlessDineInInputLabel: false,
  },
  selectedAddressOption: EMPTY_ADDRESS,
  addressInputValue: '',
  fieldErrors: {
    apt: false,
  },
};

const handlers: ActionHandlers<AddressInformationFormState> = {
  [toggleAllErrors.toString()]: (state, { value }: ToggleAllErrorsPayload) => {
    return {
      ...state,
      errorsVisibility: {
        apt: value,
        addressInput: value,
        timingOption: value,
        addressLine2: value,
        contactlessDineInInputLabel: value,
      },
    };
  },
  [initAddressForm.toString()]: () => {
    return initialState;
  },
  [setAddressInputValue.toString()]: (state, payload: SetAddressInputValuePayload) => {
    return {
      ...state,
      addressInputValue: payload.value,
      addressInputError: { type: 'invalid-address' },
      errorsVisibility: { ...state.errorsVisibility, addressInput: false },
      selectedAddressOption: {
        ...EMPTY_ADDRESS,
        apt: state.selectedAddressOption.apt,
        floor: state.selectedAddressOption.floor,
        entrance: state.selectedAddressOption.entrance,
        addressLine2: state.selectedAddressOption.addressLine2,
        label: state.selectedAddressOption.label,
      },
    };
  },
  [setErrorVisibility.toString()]: (state, payload: SetErrorVisibilityPayload) => {
    const newErrorVisibility = _.cloneDeep(state.errorsVisibility);
    newErrorVisibility[payload.error] = payload.value;
    return {
      ...state,
      errorsVisibility: newErrorVisibility,
    };
  },
  [setAddressInputError.toString()]: (state, payload: SetAddressInputErrorPayload) => {
    return {
      ...state,
      addressInputError: payload.validateAddressReason,
    };
  },
  [setFieldError.toString()]: (state, payload: SetErrorVisibilityPayload) => {
    return {
      ...state,
      fieldErrors: {
        ...state.fieldErrors,
        [payload.error]: payload.value,
      },
    };
  },
  [setSelectedAddress.toString()]: (state, payload: SetSelectedAddressPayload) => {
    const newAddress: Address = { ...payload.address };
    const currentAddress = state.selectedAddressOption;
    const addressFieldsToKeep: (keyof AddressFormFields | 'addressLine2' | 'label')[] = [
      'apt',
      'floor',
      'entrance',
      'addressLine2',
      'label',
    ];
    addressFieldsToKeep.forEach((field) => {
      payload.address[field] === undefined ? (newAddress[field] = currentAddress[field]) : noop();
    });

    return {
      ...state,
      selectedAddressOption: newAddress,
    };
  },
  [setDeliveryAddressField.toString()]: (state, payload: SetDeliveryAddressFieldPayload) => {
    const newAddress = { ..._.cloneDeep(state.selectedAddressOption), [payload.addressField]: payload.value };

    return {
      ...state,
      selectedAddressOption: newAddress,
    };
  },
};

const reducer = createReducer<AddressInformationFormState>(initialState, handlers);

export default reducer;
